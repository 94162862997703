import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import SectionBox from "../components/section-box"
import SectionBox2 from "../components/section-box2"
import ScrollAnimation from "react-animate-on-scroll"
import "animate.css/animate.min.css"

//Ilustration inports
import ilustration2 from "../images/oblakoder-home-6.png"

//Styled Components Import

import SvgBg1 from "../components/styled/SvgBg"
import GridStyled1 from "../components/styled/Grid1"
import ImageBox1 from "../components/styled/ImageBox"

const AwsForDevelopers = () => (
  <Layout>
    <SEO title="AWS for Developers" />
    <SvgBg1>
      <GridStyled1>
        <div>
          <ScrollAnimation animateIn="fadeIn" duration={1.8}>
            <h1>
              <span>AWS</span> for Developers
            </h1>
          </ScrollAnimation>
          <ScrollAnimation animateIn="fadeInUp" delay={150} duration={1.3}>
            <h3>Nephele</h3>
          </ScrollAnimation>
          <ScrollAnimation animateIn="fadeIn" delay={250} duration={1.4}>
            <SectionBox>
              <p>
                <b>Welcome developers!</b>
              </p>
            </SectionBox>
            <SectionBox2>
              <p>
                As a AWS certified architects, developers and consultant partner
                we are more than happy to help you to start with AWS development
                services and tools. Infrastructure as a code, CI/CD, Serverless
                or cloud storage are just the peak of the ice berg.
              </p>
            </SectionBox2>
            <SectionBox>
              <p>
                <b>
                  We are here to give you support and directions how to get the
                  most of the AWS cloud.
                </b>
              </p>
            </SectionBox>
            <SectionBox2>
              <p>
                Security is our specialty. Our engineers with AWS Certified
                Security - Specialty certificate will guide you through complex
                process of securing your AWS account, creating users and groups,
                defining polices and assigning roles.
              </p>
            </SectionBox2>
            <SectionBox2>
              <p>
                AWS have many services that can secure your applications and
                services, secure your data in transit and at rest and bring the
                security of entire system to the next level.
              </p>
            </SectionBox2>
          </ScrollAnimation>
        </div>
        <ImageBox1>
          <div className="png-img">
            <img src={ilustration2} alt="AWS Well-Architected" />
          </div>
          <div className="res-img">
            <img src={ilustration2} alt="AWS Well-Architected" />
          </div>
        </ImageBox1>
      </GridStyled1>
    </SvgBg1>
  </Layout>
)

export default AwsForDevelopers
